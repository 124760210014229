
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import InventoryList from "@/components/user/list/InventoryList.vue";
import { IInventoryCsvRequest } from "@/types/movement";
import { IWarehouseListRequest } from "@/types/warehouse";
import { ISelectItem } from "@/types";
import { ISupplierListRequest } from "@/types/supplier";
import { IMakerListRequest } from "@/types/maker";

@Component({
  components: { InventoryList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // pdf
  @Action("csv/userInventoryExport")
  public getInventoryCsv!: (request: IInventoryCsvRequest) => Promise<boolean>;

  // warehouse
  @Action("warehouse/userGetList")
  public getWarehouseList!: (
    request: IWarehouseListRequest
  ) => Promise<boolean>;

  @Getter("warehouse/selectItem")
  public warehouseList!: ISelectItem[];

  @Mutation("warehouse/clear")
  public clearWarehouse!: () => void;

  // supplier
  @Action("supplier/userGetList")
  public getSupplierList!: (request: ISupplierListRequest) => Promise<boolean>;

  @Action("supplier/userGet")
  public getSupplier!: (supplier_id: number) => Promise<boolean>;

  @Getter("supplier/selectItem")
  public supplierList!: ISelectItem[];

  @Getter("supplier/relationMakerSelectItem")
  public relationMakerList!: ISelectItem[];

  @Mutation("supplier/clear")
  public clearSupplier!: () => void;

  // maker
  @Action("maker/userGetList")
  public getMakerList!: (request: IMakerListRequest) => Promise<boolean>;

  @Action("maker/userGet")
  public getMaker!: (maker_id: number) => Promise<boolean>;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Getter("maker/relationSupplierSelectItem")
  public relationSupplierList!: ISelectItem[];

  @Mutation("maker/clear")
  public clearMaker!: () => void;

  // パラメータ定義
  public csv_params: IInventoryCsvRequest = {
    search_span_end: "",
    warehouse_id: 0,
    supplier_id: 0,
    maker_id: 0
  };

  // variables
  public csvSupplierList: ISelectItem[] = [];
  public csvMakerList: ISelectItem[] = [];
  public dialog = false;
  public valid = true;
  public menu = {
    date: false
  };

  // methods
  public async inventory_btn() {
    this.csv_params.search_span_end = "";
    this.csv_params.warehouse_id = 0;
    this.csv_params.supplier_id = 0;
    this.csv_params.maker_id = 0;

    this.clearWarehouse();
    this.clearSupplier();
    this.clearMaker();

    await Promise.all([
      this.getWarehouseList({ per_page: 0 }),
      this.getSupplierList({ per_page: 0 }),
      this.getMakerList({ per_page: 0 })
    ]);

    this.csvSupplierList = this.supplierList;
    this.csvMakerList = this.makerList;

    this.dialog = true;

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  public async get_csv() {
    await this.getInventoryCsv(this.csv_params);
  }

  public csv_close() {
    this.dialog = false;
  }

  public async supplierSelected() {
    if (!this.csv_params.supplier_id) {
      this.csvMakerList = this.makerList;
      return;
    }

    await this.getSupplier(this.csv_params.supplier_id);

    this.csvMakerList = this.relationMakerList;
  }

  public async makerSelected() {
    if (!this.csv_params.maker_id) {
      this.csvSupplierList = this.supplierList;
      return;
    }

    await this.getMaker(this.csv_params.maker_id);

    this.csvSupplierList = this.relationSupplierList;
  }
}
